import qs from 'qs'
import { createRouter, createWebHistory } from 'vue-router'
import { useAuth } from '@tenant/composables'
import centralGuard from '@tenant/core/guard/central-guard'
import tenantGuard from '@tenant/core/guard/tenant-guard'
import guestGuard from '@tenant/core/guard/guest-guard'
import accountantGuard from '@tenant/core/guard/accountant-guard'
import emitter from '@tenant/core/init/emitter'

const pageModules = import.meta.globEager('@tenant/modules/**/**/**/routes.js')

let routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('../components/not-found.vue'),
        meta: {
            layout: 'blank-layout',
        },
    },
]
for (const path in pageModules) {
    const { routes: moduleRoutes, meta } = pageModules[path].default

    routes = [
        ...routes,
        ...moduleRoutes.map((moduleRoute) => ({
            ...moduleRoute,
            meta: {
                ...meta,
                ...(moduleRoute.meta || {}),
            },
        })),
    ]
}

const router = createRouter({
    history: createWebHistory(),
    routes,
    parseQuery(query) {
        return qs.parse(query)
    },
    stringifyQuery(query) {
        const result = qs.stringify(query)

        return result ? result : ''
    },
})

router.beforeEach(async () => {
    emitter.emit('set-loading', false)
})

router.beforeEach(async (to, from, next) => {
    const { info, infoCentral } = useAuth()

    try {
        await Promise.all([info.execute(), infoCentral.execute()])
        next()
    } catch {
        next({ name: 'auth.login' })
    }
})

router.beforeEach(centralGuard)
router.beforeEach(accountantGuard)
router.beforeEach(tenantGuard)
router.beforeEach(guestGuard)

export default router
